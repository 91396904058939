const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://4bjqnf1jif.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://qiyf77ahue.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://ekm9zgb136.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.dev.gromit.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.gromit.forwoodsafety.com',
    WEBSOCKET: 'wss://4ln3yeq9z7.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;